<template>
    <div class="productlist">
			<ProductListContent/>
    </div>
</template>

<script>
import ProductListContent from "../components/ProductListContent.vue";

export default {
components: {
    ProductListContent,
    },
}
</script>

<style lang="scss">

</style>
